import {
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./TagList.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { TagMethodsImpl } from "../../interfaces/impl/TagMethodsImpl";
import { useSetTags, useTags } from "../context/TagContext";
import { Tag } from "../../interfaces/Tag";

const TagList = () => {
  const [loading, setLoading] = useState(true);
  const tagMethods = new TagMethodsImpl();
  const tags = useTags();
  const setTags = useSetTags();

  useEffect(() => {
    try {
      tagMethods.getAll().then((res) => {
        setTags(res);
      });
    } catch (err) {}
    setLoading(false);
  }, []);

  async function deleteTag(tagId: string): Promise<void> {
    try {
      await tagMethods.delete(tagId);
      const tags = await tagMethods.getAll();
      setTags(tags);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="TagList">
      <h5>List of tags</h5>
      {loading && (
        <CircularProgress size={40} className="LoadingTags" color="primary" />
      )}
      <div className="DivList">
        {!tags.length ? (
          <List
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              bgcolor: "background.paper",
            }}
          >
            <ListItem component="div" disablePadding>
              No tags were created
            </ListItem>
          </List>
        ) : (
          tags.map((tag: Tag) => (
            <List
              key={tag.id}
              sx={{ width: "100%", bgcolor: "background.paper" }}
            >
              <ListItem key={tag.id} component="div" disablePadding>
                <ListItemButton>
                  <ListItemText primary={`${tag.tag}`} />
                  <div onClick={() => deleteTag(tag.id ?? "")}>
                    <DeleteIcon color="error"></DeleteIcon>
                  </div>
                </ListItemButton>
              </ListItem>
            </List>
          ))
        )}
      </div>
    </div>
  );
};

export default TagList;
