import api from "../../services/api";
import { Tag, TagMethods } from "../Tag";

export class TagMethodsImpl implements TagMethods {
  async getAll(): Promise<Tag[]> {
    const tags: Tag[] = await api
      .get("")
      .then<Tag[]>((res: any) => {
        return res.data;
      })
      .catch((err) => {
        console.error(err);
        throw new Error();
      });
    return tags;
  }
  async create(data: Tag): Promise<Tag> {
    const tag: Tag = await api
      .post("", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res: any): Tag => {
        return res.data;
      })
      .catch((err) => {
        console.error(err);
        throw new Error();
      });

    return tag;
  }
  async delete(id: string) {
    return await api
      .delete(`/${id}`)
      .then((res: any): void => {
        return;
      })
      .catch((err) => {
        console.error(err);
        throw new Error();
      });
  }
}
