import React from "react";
import "./App.css";
import { TagProvider } from "./components/context/TagContext";
import Form from "./components/form/Form";
import TagList from "./components/tag-list/TagList";

function App() {
  return (
    <>
      <div className="App">
        <TagProvider>
          <Form />
          <TagList />
        </TagProvider>
      </div>
    </>
  );
}

export default App;
