import React, { useContext, useState } from "react";

const TagContext = React.createContext<any>([]);
const TagUpdateContext = React.createContext<any>([]);

export function useTags() {
  return useContext(TagContext);
}

export function useSetTags() {
  return useContext(TagUpdateContext);
}

export function TagProvider(props: { children: any }) {
  const [tags, setTags] = useState([]);

  return (
    <TagContext.Provider value={tags}>
      <TagUpdateContext.Provider value={setTags}>
        {props.children}
      </TagUpdateContext.Provider>
    </TagContext.Provider>
  );
}
