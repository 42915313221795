import React, { useState } from "react";
import "./Form.css";
import Button from "@mui/material/Button";
import { Box, CircularProgress, TextField } from "@mui/material";
import { Tag } from "../../interfaces/Tag";
import { TagMethodsImpl } from "../../interfaces/impl/TagMethodsImpl";
import { useTags, useSetTags } from "../context/TagContext";

const Form = () => {
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [data, setData] = useState<Tag>({
    tag: "",
    value: 0,
    type: "",
  });
  const tags = useTags();
  const setTags = useSetTags();

  const tagMethods = new TagMethodsImpl();
  const [submmited, setSubmmited] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e: any) =>
    setData({ ...data, [e.target.name]: e.target.value });

  function validData() {
    return Object.values(data).every((d) => d !== "");
  }

  function beforeSend() {
    setMessage("");
    setSubmmited(true);
  }

  async function submitForm() {
    beforeSend();

    if (validData()) {
      setLoading(true);

      try {
        const res = await tagMethods.create(data);
        setTags([...tags, res]);
        setMessage("Tag created");
        setStatus("success");
      } catch (error) {
        setMessage("An error has occurred");
        setStatus("error");
      }
      setSubmmited(false);
      setLoading(false);
    }
  }

  return (
    <div className="div-form">
      <Box id="form" sx={{ display: "flex" }}>
        <h3>Onboarding Challenge</h3>

        <TextField
          sx={{ mb: 2 }}
          variant="outlined"
          size="small"
          name="tag"
          label="Input a tag"
          helperText="This value is required"
          error={submmited && !data.tag}
          value={data.tag}
          onChange={handleChange}
          placeholder="Input a tag"
        ></TextField>

        <TextField
          sx={{ mb: 2 }}
          variant="outlined"
          size="small"
          name="value"
          type="number"
          label="Input a value"
          helperText="This value is required"
          error={submmited && !data.value}
          value={data.value}
          onChange={handleChange}
          placeholder="Input a value"
        ></TextField>

        <TextField
          sx={{ mb: 2 }}
          variant="outlined"
          size="small"
          name="type"
          label="Input a type"
          helperText="This value is required"
          error={submmited && !data.type}
          value={data.type}
          onChange={(e) => handleChange(e)}
          placeholder="Input a type"
        ></TextField>

        <Button variant="contained" size="small" onClick={submitForm}>
          {loading ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <>Submit form</>
          )}
        </Button>
        <p data-testid="errorMessage" className={`message ${status}`}>
          {message}
        </p>
      </Box>
    </div>
  );
};

export default Form;
